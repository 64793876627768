import React from "react";
import "./App.css";

function App() {
  return (
    <div className="container">
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Heart_coraz%C3%B3n.svg/1200px-Heart_coraz%C3%B3n.svg.png" alt="Love" />
      <h1>LoveGPT is now shifted here</h1>
      <button onClick={() => (window.location.href = "https://lovegpt.co.in")}>
        Go to LoveGPT
      </button>
    </div>
  );
}

export default App;